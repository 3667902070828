export enum RankType {
    SSS,
    SS,
    S,
    Aon,
    Aoff,
    BCDoff,
    F,
    NP,
    BCDon,
    PH_SSSPlus,
    PH_SSS,
    PH_SSPlus,
    PH_SS,
    PH_SPlus,
    PH_S,
    PH_AAAPlus,
    PH_AAA,
    PH_AAPlus,
    PH_AA,
    PH_APlus,
    PH_A,
    PH_B,
    PH_C,
    PH_D,
    PH_F,
}

export const RankConstant = {
    PH_SSSPlus: 1,
    PH_SSS: 0.98,
    PH_SSPlus: 0.96,
    PH_SS: 0.93,
    PH_SPlus: 0.90,
    PH_S: 0.86,
    PH_AAAPlus: 0.82,
    PH_AAA: 0.77,
    PH_AAPlus: 0.72,
    PH_AA: 0.67,
    PH_APlus: 0.62,
    PH_A: 0.57,
    PH_B: 0.52,
    PH_C: 0.47,
    PH_D: 0.42,
    PH_F: 0,
    SSS: 0,
    SS: 0,
    S: 0,
    Aon: 0,
    Aoff: 0,
    BCDoff: 0,
    F: 0,
    NP: 0,
    BCDon: 0,
}