import styled from "styled-components";

export const MainWrapper = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    max-width: 1280px;
    padding: 10px;
`;
