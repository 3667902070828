const TxtHeader = {
    subtitle: "评价管理工具",
    btnMode: "Cleared表格",
    btnSearch: "搜索歌曲名",
    modeChange: {
        title: "注意模式更改",
        body: "模式更改。 确定要进行吗？"
    }
}

export default TxtHeader